import React from 'react';
import Banner from './Banner';
import AboutPreview from './AboutPreview';
import Contact from './Contact';
import Footer from './Footer';

function App() {
  return (
    <>
      <Banner/>
      <AboutPreview />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
